import jq from './jq.js'
let $ = jq
let allstring = '';
// let newtring = '';
$(function () {
    // 获取返回值
    var numK = JSON.parse(localStorage.getItem("activeNames"));
    console.log(allstring,numK, "查看拿到了多少123")
    if (numK == 0) {
        let qrresult = GetQueryString();
        let modeValue = getCaption(qrresult);
        console.log(modeValue, "qrresult")
        window.localStorage.setItem('alltringTwo', allstring);
        let constArr = [];
        let constData = localStorage.getItem("dataLisTwo");
        if (allstring == "") {
            // constArr = constData.split("\n").filter(Boolean);
            $("#modeValue").val(constData) + '\n';
            console.log(constData, $("#modeValue").html(constData), "查看纠正的数据");
            console.log('3333...')
        } else {
            if (constData == null) {
                $("#modeValue").val(allstring).filter(Boolean);
                console.log('1111111...')
            } else {
                constArr += constData + '\n';
                var allstrings = allstring.concat(constArr);
                let allstringss = allstrings.substring(0, allstrings.length - 2).split('\n')
                let allstringns = [];
                for (var i = 0; i < allstringss.length; i++) {
                    console.log(allstringss[i], 'allstringss[i]')
                    if (allstringns.indexOf(allstringss[i]) == -1) {
                        allstringns.push(allstringss[i]);
                    }
                }
                var allstringa = '';
                for (var j = 0; j < allstringns.length; j++) {
                    allstringa += allstringns[j] + '\n'
                }
                console.log(allstringa, 'allstringa')
                console.log(allstringns, 'allstringns')
                // let allstringns = allstrings.substring(0,allstrings.length-2).split('\n');
                console.log(allstringns, "查看拿到了多少123no")
                if (document.getElementById("lines") != null || document.getElementById("lines") != undefined) {
                    document.getElementById("lines").text = allstringns.length;
                }
                window.localStorage.setItem('allstringnsOne', allstringa)
                $("#modeValue").val(allstringa).filter(Boolean);
                console.log('22222...')
            }
        }
    } else if (numK == 1) {
        let qrresult = GetQueryString();
        console.log(qrresult, 'qrresult')
        let modeValue = getCaption(qrresult);
        console.log(modeValue, "qrresult2")
        window.localStorage.setItem("imgTabBtn", modeValue);
        // window.localStorage.setItem('imgTabBtn', allstring);
        console.log(window.localStorage.getItem("imgTabBtn"), 'nojson11111')
        // console.log(window.localStorage.getItem("imgTabBtn"),'json1111')
        var tabNum = window.localStorage.getItem("imgTabBtn");
        $("#modeValues").val(tabNum).filter(Boolean);
        console.log(window.localStorage.getItem("imgTabBtns"), 'nojsons11111')
        // console.log(JSON.parse(window.localStorage.getItem("imgTabBtns")),'jsons1111')
        var tabNums = window.localStorage.getItem("imgTabBtns");
        $("#modeValuesTwo").val(tabNums).filter(Boolean);
    } else if (numK == 2) {
        let qrresult = GetQueryString();
        console.log(qrresult, 'qrresultss')
        let modeValue = getCaption(qrresult);
        console.log(modeValue, "qrresult3")
        window.localStorage.setItem("imgTabBtns", modeValue);
        // window.localStorage.setItem('imgTabBtns', allstring);
        console.log(window.localStorage.getItem("imgTabBtn"), 'nojsons11000')
        // console.log(JSON.parse(window.localStorage.getItem("imgTabBtn")),'jsons1100')
        var tabNumOne = window.localStorage.getItem("imgTabBtn");
        $("#modeValues").val(tabNumOne).filter(Boolean);
        console.log(window.localStorage.getItem("imgTabBtns"), 'nojsons1100011')
        // console.log(JSON.parse(window.localStorage.getItem("imgTabBtns")),'jsons110011')
        var tabNumsOne = window.localStorage.getItem("imgTabBtns");
        $("#modeValuesTwo").val(tabNumsOne).filter(Boolean);
    } else if (numK == 3) {
        let qrresult = GetQueryString();
        console.log(qrresult, 'qrresultss')
        let modeValue = getCaption(qrresult);
        console.log(modeValue, "qrresult3")
        window.localStorage.setItem("imgTabBtnsTwo", modeValue);
        // window.localStorage.setItem('imgTabBtnsTwo', allstring);
        console.log(window.localStorage.getItem("imgTabBtnsTwo"), 'nojsons11000')
        // console.log(JSON.parse(window.localStorage.getItem("imgTabBtn")),'jsons1100')
        var tabNumOne = window.localStorage.getItem("imgTabBtnsTwo");
        $("#modeValuesThree").val(tabNumOne).filter(Boolean);
    }
})
// 获取URL参数
function GetQueryString() {
    // let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    // let r = window.location.search.substr(1).match(reg); //search,查询？后面的参数，并匹配正则
    let r = "";
    if (window.location.hash.split('=')[1]) {
        r = window.location.hash.split('=')[1];
    } else {
        r = window.location.hash.split('_')[1];
    }
    if (r != null)
        // return unescape(r[2]);
        return r
    return null;
}

function getCaption(obj) {
    // http://xxxxxx/sys.html?qrresult=CODE_128,898602E5011890131335
    let index = null;
    if (obj.lastIndexOf("\,") != -1) {
        index = (obj || "").lastIndexOf("\,");
    } else {
        index = (obj || "").lastIndexOf("\,");
    }
    // let index = (obj || "");
    console.log(index, "index111")
    if (index < -1) {
        // alert("请扫描条形码！");
        return;
    }
    let localtring = localStorage.getItem('alltring');
    console.log(localtring, 'localtring')
    if (localtring != null || localtring != undefined) {
        var localtrings = [];
        localtrings = localtring.split(',').filter(Boolean);
        if (document.getElementById("lines") != null || document.getElementById("lines") != undefined) {
            document.getElementById("lines").text = localtrings.length;
            console.log('走进条件')
        }
    }
    console.log(localtring, "这是第0个")
    let newtring = localtring;

    if (localtring == null) {
        localtring = '';
        newtring = '';
    } else {
        localtring = localtring.substring(0, localtring.length - 1).split(',').filter(Boolean);
        console.log(localtring, "localtringlocaltring")
        // window.localStorage.setItem('arrGroupIdOne', localtring);
        // localtring = localtring.split(',');
        for (var i = 0; i < localtring.length; i++) {
            allstring += localtring[i] + '\n';
        }
    }

    obj = obj.substring(index + 1, obj.length);
    if (localtring == '') {
        allstring += obj + '\n';
        newtring += obj + ',';
        window.localStorage.setItem('alltring', newtring);
        var newtringn = [];
        newtringn = newtring.split(',').filter(Boolean);
        if (document.getElementById("lines") != null || document.getElementById("lines") != undefined) {
            document.getElementById("lines").text = newtringn.length;
        }
        console.log(newtringn, "这是第1个")
    } else {
        let addtype = false;
        $(localtring).each(function (i, jbj) {
            if (jbj == obj) {
                addtype = true;
                return false;
            }
        })
        if (!addtype) {
            allstring += obj + '\n';
            newtring += obj + ',';
            window.localStorage.setItem('alltring', newtring);
            var newtrings = [];
            newtrings = newtring.split(',').filter(Boolean);
            if (document.getElementById("lines") != null || document.getElementById("lines") != undefined) {
                document.getElementById("lines").text = newtrings.length;
            }
            console.log(newtrings, "这是第2个")
        }
    }

    //window.location.href="http://sao315.com/w/api/saoyisao?redirect_uri=https://dev.careld.cn/camera.html"
    console.log(obj, ",.,..")
    return obj;
}