<template>
  <div>
    <div class="header">状态管理</div>
    <div class="headerName">
      <div class="nameLeft">
        <span style="font-size: 13px; font-weight: bold; margin-left: 13px"
          >操作人：<span style="font-weight: initial">{{
            userName
          }}</span></span
        >
      </div>
      <div class="nameRight">
        <el-button
          type="submit"
          style="
            padding: 1px 10px;
            font-size: 13px;
            border-radius: 5px;
            border: 1px solid rgb(121 121 121);
            background: rgba(255, 255, 255, 1);
            margin-right: 10px;
          "
          @click="loginOut"
        >
          退出
        </el-button>
      </div>
    </div>
    <div>
      <!-- <el-tabs v-model="activeName" :before-leave="beforeTabLeave">
        <el-tab-pane label="产品出库" name="first">
          
        </el-tab-pane>
        <el-tab-pane label="更换设备" name="second">
          
        </el-tab-pane>
      </el-tabs> -->
      <div
        style="
          width: 94%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: start;
          height: 40px;
          line-height: 40px;
          border-bottom: 2px solid rgb(229, 231, 237);
          margin-left: 3%;
          margin-top: 2%;
        "
      >
        <div @click="tabBtn(tabsList.id, 0)" style="margin: 0 30px 0 0">
          <p
            id="tabId"
            style="
              font-size: 14px;
              color: #303133;
              font-weight: 500;
              color: rgb(64, 158, 255);
            "
          >
            {{ tabsList.name }}
          </p>
        </div>
        <div @click="tabBtns(tabsLists.id, 1)" style="margin: 0 30px 0 0">
          <p
            id="tabsId"
            style="font-size: 14px; color: #303133; font-weight: 500"
          >
            {{ tabsLists.name }}
          </p>
        </div>
      </div>
      <div style="width: 94%; margin-left: 3%">
        <div v-if="tabConent">
          <div class="Table">
            <p style="margin-bottom: 5px; font-size: 17px; font-weight: bold">
              设备选项
            </p>

            <el-form ref="form" :model="form" label-width="130px">
              <div class="headTable">
                <div class="nameLeft">
                  <el-form-item label="设备状态修改为：">
                    <el-select
                      v-model="form.resource"
                      placeholder="请选择"
                      style="margin-top: 1%"
                    >
                      <el-option
                        style="padding: 7px 8px;"
                        v-for="item in resourceTable"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                    <!-- <el-radio-group v-model="form.resource">
                      <el-radio label="3">沉默</el-radio>
                      <el-radio label="4">待激活</el-radio>
                      <el-radio label="5">待换机</el-radio>
                      <el-radio label="2">禁用</el-radio>
                    </el-radio-group> -->
                  </el-form-item>
                </div>
              </div>
              <div class="headTable" style="margin-top: 4%">
                <div class="nameLeft">
                  <el-form-item label="选择销售分组：">
                    <!-- <el-select
                      v-model="form.region"
                      placeholder="请选择"
                      style="margin-top: 1%"
                    >
                      <el-option
                        style="padding: 6px 8px"
                        value="0"
                        label="保留原有分组"
                      ></el-option>
                      <el-option
                        style="padding: 6px 8px"
                        v-for="item in selectTable"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                      ></el-option>
                    </el-select> -->
                    <el-autocomplete
                      v-model="form.region"
                      placeholder="请输入"
                      :fetch-suggestions="querySearch"
                      :trigger-on-focus="true"
                      @select="handleSelect"
                      @input="inputShow"
                      @change="$forceUpdate()"
                      clearable
                    >
                      <template slot-scope="{ item }">
                        <div
                          class="name"
                          id="autoKuang"
                          v-html="brightenKeyword(item.name, form.region)"
                        ></div>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="main" style="margin-bottom: 5%">
            IMEI列表
            <a
              href="https://www.996315.com/api/scan/?redirect_uri=http://192.168.0.2:8081/index.html"
              style="margin-bottom: 3px"
              @click="imgBtn"
              v-if="form.resource != '6'"
              ><img
                style="width: 7%; margin-left: 10px"
                src="http://cdn.careld.cn/datacenter/user/photo/581648100119562.png" /></a
            ><button
              type="submit"
              style="
                float: right;
                margin-right: 6px;
                padding: 2px 15px;
                font-size: 13px;
                border: 1px solid rgb(121 121 121);
                background: rgba(255, 255, 255, 1);
                border-radius: 5px;
              "
              @click="submit"
            >
              确定提交</button
            ><br />
            <div
              class="headTable"
              style="margin-top: 4%; height: 50px"
              v-if="form.resource == '6'"
            >
              <div class="nameLeft">
                <span
                  style="
                    text-align: right;
                    vertical-align: middle;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #606266;
                    line-height: 50px;
                    padding: 0 12px 0 0;
                    font-weight: 700;
                  "
                  >恢复删除设备:</span
                >
                <input
                  type="text"
                  id="modeValuesThree"
                  maxlength="15"
                  placeholder="输入需要恢复的IMEI号"
                  style="width: 50%; height: 28px; padding: 0px 3px"
                  @input="calcsThree()"
                />
                <a
                  href="https://www.996315.com/api/scan/?redirect_uri=http://192.168.0.2:8081/index.html"
                  style="margin-bottom: 3px"
                  @click="imgBtnThree"
                  ><img
                    style="width: 7%; margin-left: 10px"
                    src="http://cdn.careld.cn/datacenter/user/photo/581648100119562.png"
                /></a>
              </div>
            </div>
            <div v-else>
              <textarea
                type="text"
                id="modeValue"
                placeholder="请扫码识别或手动输入imei号(长度限制15,且只可输入数字)"
                style="
                  width: 98%;
                  height: auto;
                  min-height: 380px;
                  margin-top: 8px;
                "
                @input="calc()"
              ></textarea>
              <span>已经输入<a id="lines">0</a>/200条数据</span>
            </div>
          </div>
        </div>
        <div v-if="tabConents">
          <div class="Table">
            <p style="margin-bottom: 5px; font-size: 17px; font-weight: bold">
              原设备选项
            </p>
            <div class="headTable" style="margin-top: 4%; height: 50px">
              <div class="nameLeft">
                <span
                  style="
                    text-align: right;
                    vertical-align: middle;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #606266;
                    line-height: 50px;
                    padding: 0 12px 0 0;
                    font-weight: 700;
                  "
                  >原始设备:</span
                >
                <input
                  type="text"
                  id="modeValues"
                  maxlength="15"
                  placeholder="输入原设备IMEI号"
                  style="width: 50%; height: 28px; padding: 0px 3px"
                  @input="calcs()"
                />
                <a
                  href="https://www.996315.com/api/scan/?redirect_uri=http://192.168.0.2:8081/index.html"
                  style="margin-bottom: 3px"
                  @click="imgBtns"
                  ><img
                    style="width: 7%; margin-left: 10px"
                    src="http://cdn.careld.cn/datacenter/user/photo/581648100119562.png"
                /></a>
              </div>
            </div>
          </div>
          <div class="Table">
            <p style="margin-bottom: 5px; font-size: 17px; font-weight: bold">
              新设备选项
            </p>
            <div class="headTable" style="margin-top: 4%; height: 50px">
              <div class="nameLeft">
                <span
                  style="
                    text-align: right;
                    vertical-align: middle;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #606266;
                    line-height: 50px;
                    padding: 0 12px 0 0;
                    font-weight: 700;
                  "
                  >全新设备:</span
                >
                <input
                  type="text"
                  id="modeValuesTwo"
                  maxlength="15"
                  placeholder="输入新设备IMEI号"
                  @input="calcsTwo()"
                  style="width: 50%; height: 28px; padding: 0px 3px"
                />
                <a
                  href="https://www.996315.com/api/scan/?redirect_uri=http://192.168.0.2:8081/index.html"
                  style="margin-bottom: 3px"
                  @click="imgBtnsTwo"
                  ><img
                    style="width: 7%; margin-left: 10px"
                    src="http://cdn.careld.cn/datacenter/user/photo/581648100119562.png"
                /></a>
              </div>
            </div>
            <div class="headTable" style="margin-top: 4%; height: 50px">
              <div class="nameLeft">
                <span
                  style="
                    text-align: right;
                    vertical-align: middle;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #606266;
                    line-height: 50px;
                    padding: 0 12px 0 0;
                    font-weight: 700;
                  "
                  >生效方式:</span
                >
                <el-select
                  v-model="regions"
                  placeholder="请选择"
                  style="margin-top: 1%"
                >
                  <el-option
                    style="padding: 6px 8px"
                    v-for="item in selectTables"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div style="margin-top: 20%; text-align: center">
            <button
              type="submit"
              style="
                padding: 5px 30px;
                font-size: 13px;
                border: 1px solid rgb(121 121 121);
                background: rgba(255, 255, 255, 1);
                border-radius: 5px;
              "
              @click="submits"
            >
              确定提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import $ from "jquery";
export default {
  comments: {
    $,
  },
  data() {
    return {
      tabsList: {
        id: 0,
        name: "产品出库",
      },
      tabsLists: {
        id: 1,
        name: "更换设备",
      },
      tabConent: true,
      tabConents: false,
      form: {
        resource: "3",
        region: "0",
      },
      regions: 0,
      selectTable: [],
      selectTables: [
        {
          id: 0,
          name: "立即生效",
        },
        {
          id: 1,
          name: "绑定生效",
        },
      ],
      resourceTable: [
        {
          id: "2",
          name: "禁用",
        },
        {
          id: "3",
          name: "沉默",
        },
        {
          id: "4",
          name: "待激活",
        },
        {
          id: "5",
          name: "待换机",
        },
        {
          id: "6",
          name: "恢复删除",
        },
      ],
      usernames: null,
      arrGroupId: [],
      allstring: "",
      modeList: [],
      cList: [],
      allStrings: "",
      lisTatus: false,
      modelArr: null,
      userName: "",
      activeName: "first",
      nameList: "",
      newList: "",
      modelOne: "",
      modelTwo: "",
      modelThree: "",
      resErrorList: [],
      resfailList: [],
      resfailListCode: [],
      resSuccessList: [],
      resSucceList: [],
      resSuccessCode: [],
      addfailCount: null,
      addsuccessCount: null,
      addtotalCount: null,
      successEroor: [],
      addGroupId: null,
      addStatus: null,
      addBind: null,
      hisNos: null,
      regionIds: null,
    };
  },
  destroyed() {
    localStorage.removeItem("alltringTwo");
  },
  created() {
    apiShout.getGroupAsc().then((res) => {
      if (res.data.code === 200) {
        this.selectTable = res.data.result;
        console.log(res, "选择销售分组");
      }
    });
  },
  mounted() {
    var numK = JSON.parse(localStorage.getItem("activeNames"));
    //   let dom = document.createElement('script')
    //   dom.src = 'https://cdn.bootcdn.net/ajax/libs/jquery/2.2.0/jquery.js'
    //   document.body.appendChild(dom)
    require("../../utils/camera.js");
    this.usernames = JSON.parse(localStorage.getItem("Username"));
    this.userName = this.usernames.userName;
    // this.calc();
    console.log(numK, "numK");
    if (numK == 0 || numK == null) {
      this.tabConent = true;
      this.tabConents = false;
      $("#tabId").css("color", "#409EFF");
      $("#tabsId").css("color", "#303133");
      // let nameArr = [];
      let constArr = [];
      let cListOne = localStorage.getItem("alltringTwo");
      if (cListOne == null) {
        let constData = localStorage.getItem("dataLisTwo");
        constArr = constData;
        console.log(constArr, "显示");
        $("#modeValue").val(constArr) + "\n";
        document.getElementById("lines").text = document
          .getElementById("modeValue")
          .value.split("\n").length;
        // console.log(constArr.length, "查看显示多少条");
      } else {
        console.log(cListOne, "查看显示");
        // nameArr = cListOne.split("\n").filter(Boolean);
        // document.getElementById("lines").text = nameArr.length;
      }
    } else if (numK == 1) {
      this.tabConent = false;
      this.tabConents = true;
      $("#tabId").css("color", "#303133");
      $("#tabsId").css("color", "#409EFF");
      console.log("走的2");
      let imgTabBtn = window.localStorage.getItem("imgTabBtn");
      $("#modeValues").val(imgTabBtn);
    } else if (numK == 2) {
      this.tabConent = false;
      this.tabConents = true;
      $("#tabId").css("color", "#303133");
      $("#tabsId").css("color", "#409EFF");
      let imgTabBtns = window.localStorage.getItem("imgTabBtns");
      $("#modeValuesTwo").val(imgTabBtns);
      console.log("走的3");
    } else if (numK == 3) {
      this.tabConent = true;
      this.tabConents = false;
      this.form.resource = "6";
      $("#tabId").css("color", "#409EFF");
      $("#tabsId").css("color", "#303133");
      let imgTabBtnsTwo = window.localStorage.getItem("imgTabBtnsTwo");
      $("#modeValuesThree").val(imgTabBtnsTwo);
      console.log("走的3");
    }
    //第二种方法
    if (window.performance.navigation.type == 1) {
      console.log("页面被刷新");
      window.localStorage.removeItem("idNum");
    } else {
      console.log("首次被加载");
    }
    apiShout.getGroupAsc().then((res) => {
      console.log("销售分组：", res.data);
      if (res.data.code == 200) {
        // this.saleGroup = res.data.result;
        console.log(this.form.region, "this.form.region");
        res.data.result.push({ id: 0, name: "保留原有分组" });
        res.data.result.forEach((element) => {
          if (this.form.region == element.id) {
            this.form.region = element.name;
            this.regionIds = element.id;
          }
        });
      }
    });
  },
  methods: {
    //API账户模糊搜索
    querySearch(queryString, cb) {
      console.log(queryString, cb, "123");
      apiShout.getGroupAsc().then((res) => {
        if (res.data.code === 200) {
          console.log("queryString", queryString);
          var restaurants = res.data.result;
          if (
            queryString == "" ||
            queryString == undefined ||
            queryString == null
          ) {
            restaurants.push({ id: 0, name: "保留原有分组" });
            var resultsTwo = restaurants.filter((item) => item.name);
            console.log(resultsTwo, restaurants, "restaurants");
            cb(resultsTwo);
          } else {
            restaurants.push({ id: 0, name: "保留原有分组" });
            console.log(restaurants, "restaurants");
            var results = restaurants.filter(
              (item) => item.name.indexOf(queryString) >= 0
            );
            // 调用 callback 返回建议列表的数据
            console.log(results, "results");
            cb(results);
          }
        }
      });
    },
    brightenKeyword(val, keyword) {
      if (keyword == undefined || keyword == null || keyword == "") {
        console.log(keyword, "空关键词");
        return val;
      } else {
        const Reg = new RegExp(keyword, "gi");
        console.log(Reg, val, "valval");
        if (val) {
          return val.replace(
            Reg,
            `<span style="font-weight: bold;">${keyword}</span>`
          );
        }
      }
    },
    handleSelect(item) {
      console.log(item, "...");
      this.form.region = item.name;
      this.regionIds = item.id;
    },
    inputShow(item) {
      console.log(item, "itemitem");
      this.$forceUpdate();
    },
    //切换标题1
    tabBtn(id, index) {
      console.log(id, "idsss");
      let idsNum = JSON.parse(localStorage.getItem("idNum"));
      console.log(id, idsNum, "idsNum11");
      if (idsNum == null) {
        return false;
      }
      if (id !== idsNum || idsNum == undefined) {
        console.log("00000");
        let ifAlltring = localStorage.getItem("alltring");
        console.log(ifAlltring, "ifAlltring");
        var modelOne = $("#modeValues").val() || "";
        var modelThree = $("#modeValuesTwo").val() || "";
        if (ifAlltring !== null || modelOne !== "" || modelThree !== "") {
          this.$confirm("修改未保存确定将被清除, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              console.log("确认");
              window.localStorage.removeItem("alltring");
              window.localStorage.removeItem("alltringTwo");
              window.localStorage.removeItem("imgTabBtn");
              window.localStorage.removeItem("imgTabBtns");
              window.localStorage.removeItem("imgTabBtnsTwo");
              let constData = localStorage.getItem("dataLisTwo");
              $("#modeValue").val(constData);
              $("#modeValues").val(constData);
              $("#modeValuesTwo").val(constData);
              $("#modeValuesThree").val(constData);
              this.tabConent = true;
              this.tabConents = false;
              $("#tabId").css("color", "#409EFF");
              $("#tabsId").css("color", "#303133");
              window.localStorage.setItem("idNum", index);
            })
            .catch(() => {});
        } else {
          this.tabConent = true;
          this.tabConents = false;
          $("#tabId").css("color", "#409EFF");
          $("#tabsId").css("color", "#303133");
          window.localStorage.setItem("idNum", index);
        }
      }
    },
    //切换标题2
    tabBtns(id, index) {
      console.log(id, "idid");
      let idsNum = JSON.parse(localStorage.getItem("idNum"));
      console.log(id, idsNum, "idsNum22");
      if (id != idsNum || idsNum == undefined) {
        console.log("12312");
        let ifAlltring = localStorage.getItem("alltring");
        console.log(ifAlltring, "ifAlltring");
        // if (ifAlltring !== null) {
        //   var strIfall = ifAlltring.lastIndexOf(",");
        //   var strIfalla = ifAlltring.substring(0, strIfall);
        //   var strIfallb = ifAlltring.substring(strIfall + 1);
        //   var strIfallres = strIfalla + strIfallb;
        //   ifAlltring = strIfallres;
        //   console.log(ifAlltring, "ifAlltring截取");
        // }
        var modelOne = $("#modeValue").val() || "";
        var modelFour = $("#modeValuesThree").val() || "";
        // var modelThree = $("#modeValuesTwo").val()  || "";
        if (ifAlltring !== null || modelOne !== "" || modelFour !== "") {
          this.$confirm("修改未保存确定将被清除, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              console.log("确认");
              window.localStorage.removeItem("alltring");
              window.localStorage.removeItem("alltringTwo");
              window.localStorage.removeItem("imgTabBtn");
              window.localStorage.removeItem("imgTabBtns");
              window.localStorage.removeItem("imgTabBtnsTwo");
              let constData = localStorage.getItem("dataLisTwo");
              $("#modeValue").val(constData);
              $("#modeValues").val(constData);
              $("#modeValuesTwo").val(constData);
              $("#modeValuesThree").val(constData);
              this.modeList = [];
              this.tabConent = false;
              this.tabConents = true;
              $("#tabId").css("color", "#303133");
              $("#tabsId").css("color", "#409EFF");
              window.localStorage.setItem("idNum", index);
            })
            .catch(() => {});
        } else {
          this.tabConent = false;
          this.tabConents = true;
          $("#tabId").css("color", "#303133");
          $("#tabsId").css("color", "#409EFF");
          window.localStorage.setItem("idNum", index);
        }
      }
    },
    imgBtn() {
      window.localStorage.setItem("activeNames", 0);
    },
    imgBtns() {
      window.localStorage.setItem("activeNames", 1);
    },
    imgBtnsTwo() {
      window.localStorage.setItem("activeNames", 2);
    },
    imgBtnThree() {
      window.localStorage.setItem("activeNames", 3);
    },
    loginOut() {
      //   this.usernames = JSON.parse(localStorage.getItem("Username"));
      console.log(this.usernames.userName, "退出带参数");
      this.$confirm("确定退出登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let that = this;
          that
            .$http({
              url: "/too/login/logOut",
              params: { name: this.usernames.userName },
              method: "POST",
            })
            .then((res) => {
              if (res.data.code == 200) {
                that.$message({
                  message: "成功退出登录",
                  type: "success",
                });
                that.$router.push("./");
                window.localStorage.removeItem("Username");
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("loginuser");
                window.localStorage.removeItem("alltring");
                window.localStorage.removeItem("dataList");
                window.localStorage.removeItem("alltringTwo");
                window.localStorage.removeItem("dataLisTwo");
                window.localStorage.removeItem("activeNames");
                window.localStorage.removeItem("imgTabBtn");
                window.localStorage.removeItem("imgTabBtns");
                window.localStorage.removeItem("imgTabBtnsTwo");
                window.localStorage.removeItem("idNum");
                // window.localStorage.removeItem("arrGroupIdOne");
              } else {
                let idNames = JSON.parse(localStorage.getItem("Username"));
                that
                  .$http({
                    url: "/too/center/weblog/save",
                    params: {
                      callback: "",
                      errorCode: res.data.code,
                      errorExplain: res.data.msg,
                      interfaceUrl: res.config.url,
                      menuName: "库管系统-退出登录",
                      menuUrl: this.$route.path,
                      params: res.config.params,
                      source: "库管系统",
                      userId: idNames.id,
                      userName: idNames.name,
                    },
                    headers: {
                      "Content-Type": "application/json;charset=UTF-8",
                    },
                    method: "get",
                  })
                  .then((res) => {
                    if (res.data.code === 200) {
                      console.log();
                    }
                  });
              }
            });
        })
        .catch(() => {});
    },
    submit() {
      console.log(this.lisTatus, $("#modeValuesThree").val(), "this.lisTatus");
      if (this.form.resource == "6") {
        if (
          $("#modeValuesThree").val() == "" ||
          $("#modeValuesThree").val() == undefined
        ) {
          console.log("imei为空");
        } else {
          this.$confirm("是否继续提交?", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          }).then(() => {
            let objTwos = {
              imei: $("#modeValuesThree").val(),
            };
            apiShout.recoveryEquipment(objTwos).then((res) => {
              if (res.data.code == 200) {
                console.log(res.data.result, "查看回复imei");
                this.$message({
                  message: "恢复成功！",
                  type: "success",
                });
                $("#modeValuesThree").val('');
              } else {
                this.$message.error(res.data.msg);
              }
            });
          });
        }
      } else {
        this.$confirm("是否继续提交?", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        }).then(() => {
          if (this.lisTatus === false) {
            let cListOne = localStorage.getItem("alltringTwo");
            let cListOnes = localStorage.getItem("dataLisTwo");
            let modelTexts = $("#modeValue").val() || "";
            if (cListOne != null && cListOnes != null) {
              let allnumString = localStorage.getItem("allstringnsOne");
              // let clistonea = cListOne.substring(0,cListOne.length-2).split('\n');
              // let clistoneb = cListOnes.substring(0,cListOnes.length-2).split('\n');
              // let strClist = clistonea.concat(clistoneb);
              // console.log(strClist)
              this.modelArr = allnumString
                .substring(0, allnumString.length - 1)
                .split("\n");
              console.log(this.modelArr, "提交拼接了上一个");
            } else if (cListOne == null && modelTexts == "") {
              console.log("通过");
            } else if (cListOne == null) {
              this.modelArr =
                null ||
                cListOnes.substring(0, cListOnes.length - 1).split("\n");
              console.log(this.modelArr, "查询收到的输入号码3");
              this.modelArr = this.modelArr.filter(Boolean);
              this.lisTatus = true;
            } else {
              this.modelArr = cListOne
                .substring(0, cListOne.length - 1)
                .split("\n");
              console.log(this.modelArr, "查询收到的输入号码2");
              this.modelArr = this.modelArr.filter(Boolean);
              this.lisTatus = true;
            }
          } else {
            console.log($("#modeValue").val(), '$("#modeValue").val()');
            if (this.modeList > 0) {
              this.modelArr = this.modeList;
            } else {
              let modeListTwo = $("#modeValue").val().split("\n");
              modeListTwo = modeListTwo.filter(Boolean);
              this.modelArr = modeListTwo;
            }
            this.modelArr = this.modeList;
            //   arr.push(this.modeList.replace(',').replace(/[\n]/g,""));
            console.log(this.modelArr, "查询收到的输入号码");
          }
          let num = 0;
          console.log(this.modelArr.length, "查看数据");
          var resData = [];
          for (var i = 0, len = this.modelArr.length; i < len; i += 20) {
            resData.push(this.modelArr.slice(i, i + 20));
          }
          resData.forEach((item) => {
            console.log(this.form.resource, "form.resource");
            let objs = {
              groupId: this.regionIds,
              imeis: item,
              status: this.form.resource,
            };
            console.log(objs, "提交查询格式");
            apiShout.updateGroup(objs).then((res) => {
              num++;
              if (res.data.code === 200) {
                console.log("成功进入这里");
                this.resErrorList.push(res.data.result.failList);
                this.resSuccessList.push(res.data.result.bindList);
                this.successEroor.push(res.data.result);
                let addfailCount = 0;
                let addsuccessCount = 0;
                let addtotalCount = 0;
                let bind = null;
                let groupId = null;
                let status = "";
                let hisNo = null;
                this.successEroor.forEach((item) => {
                  addfailCount += item.fail;
                  addsuccessCount += item.success;
                  addtotalCount += item.total;
                  bind = item.bind;
                  groupId = item.groupId;
                  status = item.status;
                  hisNo = item.hisNo;
                });
                this.addfailCount = addfailCount;
                this.addsuccessCount = addsuccessCount;
                this.addtotalCount = addtotalCount;
                this.addGroupId = groupId;
                this.addStatus = status;
                this.addBind = bind;
                this.hisNos = hisNo;
                if (this.resErrorList.length > 0) {
                  this.resErrorList.forEach((item) => {
                    console.log(item, "查询数据循环双数组");
                    this.resfailList = item;
                    console.log(this.resfailList, "this.resfailListss");
                  });
                  this.resfailListCode = this.resfailListCode.concat(
                    this.resfailList
                  );
                }
                if (this.resSuccessList.length > 0) {
                  this.resSuccessList.forEach((item) => {
                    console.log(item, "查询数据循环双数组");
                    this.resSucceList = item;
                    console.log(this.resSucceList, "this.resSucceListss");
                  });
                  this.resSuccessCode = this.resSuccessCode.concat(
                    this.resSucceList
                  );
                }
                console.log(num, resData.length, "123resData");
                if (num == resData.length) {
                  this.$message({
                    message: "提交成功！",
                    type: "success",
                  });
                  let SuccessData = {
                    bind: this.addBind,
                    bindList: this.resSuccessCode,
                    fail: this.addfailCount,
                    failList: this.resfailListCode,
                    groupId: this.addGroupId,
                    status: this.addStatus,
                    success: this.addsuccessCount,
                    total: this.addtotalCount,
                    hisNo: this.hisNos,
                  };
                  console.log(SuccessData, "进入进来numSuccessData");
                  localStorage.setItem("dataList", JSON.stringify(SuccessData));
                  console.log(this.resfailListCode, "this.resfailListCode");
                  console.log(this.resSuccessCode, "this.resSuccessCode");
                  //跳转页面
                  this.$router.push("./submit");
                }
              } else {
                console.log("错误走入这里");
                this.$message.error(res.data.msg);
              }
            });
          });
        });
      }
    },
    submits() {
      console.log(this.regions, "regions");
      this.$confirm("是否继续提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let objs = {
            oldImei: $("#modeValues").val(),
            newImei: $("#modeValuesTwo").val(),
          };
          console.log(objs, "提交查询格式");
          if (this.regions == 1) {
            apiShout.repeatEquipmentRecord(objs).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: "提交成功！",
                  type: "success",
                });
                // this.$router.push("./submit");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            apiShout.repeatEquipment(objs).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: "提交成功！",
                  type: "success",
                });
                $("#modeValues").val("");
                $("#modeValuesTwo").val("");
                // this.$router.push("./submit");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        })
        .catch(() => {});
    },
    calc() {
      document.getElementById("lines").text = document
        .getElementById("modeValue")
        .value.split("\n").length;
      console.log(
        document.getElementById("modeValue").value,
        "document.getElementByIdvaluuuu"
      );
      this.modeList = $("#modeValue").val();
      this.lisTatus = true;
      if (document.getElementById("lines").text <= 200) {
        this.allstring = $("#modeValue").val();
        let modeltext = $("#modeValue").val().split("\n");
        modeltext = modeltext.filter(Boolean);
        console.log(modeltext, "1231....");
        let arr = [];
        arr.push(modeltext);
        this.modeList = modeltext;
        console.log(arr, "查询");
        window.localStorage.setItem("alltring", this.modeList + ",");
        window.localStorage.setItem("alltringTwo", this.modeList);
        window.localStorage.setItem("dataLisTwo", "");
        console.log(modeltext, "modeltext");
        var value = document.getElementById("modeValue").value;
        let allLine = value.split("\n");
        let lastLine = allLine.pop();
        console.log(lastLine, "lastLine");
        let re = /[a-zA-Z]/;
        if (re.test(lastLine)) {
          console.log("包含了数字与字母");
          if (lastLine.length > 12) {
            console.log(lastLine, "数字与字母");
            console.log("走截取数字字母");
            lastLine = lastLine.substr(0, 12) + "\n" + lastLine.substr(12);
            allLine.push(lastLine);
            // 将数组转化为字符串
            document.getElementById("modeValue").value = allLine.join("\n");
          }
        } else {
          console.log(lastLine, "纯数字");
          if (lastLine.length > 15) {
            console.log("走截取");
            lastLine = lastLine.substr(0, 15) + "\n" + lastLine.substr(15);
            allLine.push(lastLine);
            document.getElementById("modeValue").value = allLine.join("\n");
          }
        }
      } else {
        alert("超过了200条录入限制");
        $("#modeValue").val(this.allstring);
        document.getElementById("lines").text =
          document.getElementById("lines").text - 1;
      }
      $("#modeValue")
        .keyup(function () {
          $(this).val(
            $(this)
              .val()
              .replace(/[^0-9A-Z\s]+/gi, "")
          );
        })
        .bind("paste", function () {
          $(this).val(
            $(this)
              .val()
              .replace(/[^0-9A-Z\s]+/gi, "")
          );
        });
    },
    calcs() {
      window.localStorage.setItem("imgTabBtn", $("#modeValues").val());
      this.modeList = document.getElementById("modeValues").value;
      this.allstring = $("#modeValues").val();
      let modeltext = $("#modeValues").val().split("\n");
      modeltext = modeltext.filter(Boolean);
      console.log(modeltext, "123....");
      let arr = [];
      arr.push(modeltext);
    },
    calcsTwo() {
      window.localStorage.setItem("imgTabBtns", $("#modeValuesTwo").val());
      this.modeList = document.getElementById("modeValuesTwo").value;
      this.allstring = $("#modeValuesTwo").val();
      let modeltext = $("#modeValuesTwo").val().split("\n");
      modeltext = modeltext.filter(Boolean);
      console.log(modeltext, "123....");
      let arr = [];
      arr.push(modeltext);
    },
    calcsThree() {
      window.localStorage.setItem("imgTabBtnsTwo", $("#modeValuesThree").val());
      this.modeList = document.getElementById("modeValuesThree").value;
      this.allstring = $("#modeValuesThree").val();
      let modeltext = $("#modeValuesThree").val().split("\n");
      modeltext = modeltext.filter(Boolean);
      console.log(modeltext, "123....");
      let arr = [];
      arr.push(modeltext);
    },
  },
};
</script>
<style>
.el-message-box {
  width: 80% !important;
}
body {
  position: inherit !important;
}
.el-select-dropdown__item{
  line-height: normal!important;
}
</style>
<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
/deep/.el-tabs__header {
  width: 94%;
  margin-left: 3%;
  margin-top: 3%;
}
/deep/ .el-radio__label {
  font-size: 14px;
  padding-left: 6px;
  margin-right: 18px;
}
/deep/ .el-input--suffix .el-input__inner {
  height: 30px;
  line-height: 30px;
  margin-top: 6px;
}
/deep/.el-input__icon {
  line-height: 30px;
}
.header {
  width: 100%;
  line-height: 37px;
  background-color: rgba(242, 242, 242, 1);
  text-align: center;
  font-size: 13px;
  position: fixed;
  top: 0;
}

.headerName {
  width: 94%;
  margin-left: 3%;
  height: 38px;
  background: rgba(215, 215, 215, 1);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  margin-top: 15%;
}

.Table {
  width: 94%;
  margin-left: 3%;
  margin-top: 6%;
}

.headTable {
  width: 100%;
  height: 40px;
  background: rgba(242, 242, 242, 1);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid rgba(121, 121, 121, 1);
}

.checkbox-inline,
.radio-inline {
  font-size: 12px;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 2px 0 0;
}

.form-control {
  display: inline;
  width: 150px;
  height: 26px;
  padding: 0px;
  font-size: 12px;
}

.main {
  width: 94%;
  margin-left: 3%;
  margin-top: 6%;
}
</style>